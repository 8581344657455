import React, { useEffect, useState } from "react"
import axios from "axios"

import { Container, Row, Col } from "./Grid"
import Heading from "./Heading";
import Snippet from "./Snippet";
import MicarMarketServicesTable from "./micarTransparencyData/MicarMarketServicesTable"

function MicarMarketServices() {
  const [preTradeMarketsServices, setPreTradeMarketsServices] = useState([]);
  const [postTradeMarketsServices, setPostTradeMarketsServices] = useState([]);

  // Titles and subtitles Snippets
  const pageTitle = Snippet({
    id: "3ZZUnIqikAIxdZdwCHmoxB",
    field: "header"
  });
  const preTradeTitle = Snippet({
    id: "6hCUALfwUg3aoU8P6wqT1I",
    field: "header"
  });
  const preTradeSubTitle = Snippet({
    id: "3aSfs6IS7h52TOYplGHc3R",
    field: "text"
  });
  const postTradeTitle = Snippet({
    id: "7jxeqfpM1hOKdxZnibbXwu",
    field: "header"
  });
  const postTradeSubTitle = Snippet({
    id: "7BMAOVT8xEawzn2QfBvdAW",
    field: "text"
  });

  // Api URL Snippets
  const marketsApiUrl = Snippet({
    id: "3Xuew5CEuvZWgGymu6OkP6",
    field: "linkUrl"
  });

  // Table Snippets
  const tableHeadersString = Snippet({
    id: "3DE9K7lbOflXhMThOR5O8i",
    field: "text"
  }); // comma separated headers
  const linkText = Snippet({
    id: "3teidLLBD1W6Rjoac1CawD",
    field: "linkText"
  });
  const preTradeServiceNamePrefix = Snippet({
    id: "2IcPilNShhGCm3Cc4hlukX",
    field: "text"
  });
  const postTradeServiceNamePrefix = Snippet({
    id: "731Vk0L93GaeOQd0qfYyKY",
    field: "text"
  });
  const downloadServicePathEnding = Snippet({
    id: "4HwpIP6TtD0qQlt1AKjRT6",
    field: "linkUrl"
  });

  const tableHeaders = tableHeadersString.split(",");

  useEffect(() => {
    const fetchMarketsData = async () => {
      try {
        const apiResponse = await axios.get(marketsApiUrl);
        const markets = apiResponse.data;
        const locale = document.documentElement.lang || "en";
        const downloadServicePath = `/${locale}${downloadServicePathEnding}`;

        setPreTradeMarketsServices(markets.map(market => ({
          name: `${preTradeServiceNamePrefix} ${market.id.toUpperCase()}`,
          link: `${downloadServicePath}?asset_id=${market.base.id}&type=pretrade`,
          linkText: linkText
        })));

        setPostTradeMarketsServices(markets.map(market => ({
          name: `${postTradeServiceNamePrefix} ${market.id.toUpperCase()}`,
          link: `${downloadServicePath}?asset_id=${market.base.id}&type=posttrade`,
          linkText: linkText
        })));
      } catch (error) {
        console.error("Error fetching markets data:", error);
      }
    };

    fetchMarketsData();
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={10} xl={8}>
          <Heading type="h1" className="h1">
            {pageTitle}
          </Heading>

          <MicarMarketServicesTable
            services={preTradeMarketsServices}
            title={preTradeTitle}
            subTitle={preTradeSubTitle}
            tableHeaders={tableHeaders}
          />

          <MicarMarketServicesTable
            services={postTradeMarketsServices}
            title={postTradeTitle}
            subTitle={postTradeSubTitle}
            tableHeaders={tableHeaders}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default MicarMarketServices;
