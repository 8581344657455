import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"

import AnnouncementList from "../components/AnnouncementList"
import CTA from "../components/CTA"
import CTASignupWithDeviceImage from "../components/CTASignupWithDeviceImage"
import Device from "../components/Device"
import FAQTopThree from "../components/FAQTopThree"
import FAQs from "../components/FAQs"
import FaqCollection from "../components/FaqCollection"
import Hero from "../components/Hero"
import ImageContentful from "../components/ImageContentful"
import InstitutionForm from "../components/InstitutionForm"
import MarkdownBlock from "../components/MarkdownBlock"
import MediaCoverage from "../components/MediaCoverage"
import NewsletterSignup from "../components/NewsletterSignup"
import OrgChart from "../components/OrgChart"
import PartnerLogosRow from "../components/PartnerLogosRow"
import PressDownloads from "../components/PressDownloads"
import PressLinks from "../components/PressLinks"
import PressLogos from "../components/PressLogos"
import PressReleases from "../components/PressReleases"
import PriceTable from "../components/PriceTable"
import PriceTicker from "../components/PriceTicker"
import Stage from "../components/Stage"
import SupportForm from "../components/SupportForm"
import Text2Cols from "../components/Text2Cols"
import Text3Cols from "../components/Text3Cols"
import TextAndImageBlock from "../components/TextAndImageBlock"
import TextBlock from "../components/TextBlock"
import TextBlockWithAside from "../components/TextBlockWithAside"
import TextWithHeaderLeft from "../components/TextWithHeaderLeft"
import TradingParticipantsTable from "../components/TradingParticipantsTable"
import TrustSeals from "../components/TrustSeals"
import YouTubePlayer from "../components/YouTubePlayer"
import HomeBenefits from "../components/home/Benefits"
import MicarMarketServices from "../components/MicarMarketServices"
import MicarTransparencyDataTable from "../components/MicarTransparencyDataTable"
import HomeBenefitsWithEmailPromo from "../components/home/BenefitsWithEmailPromo"
import HomeBenefitsWithPromo from "../components/home/BenefitsWithPromo"
import InstitutionsBenefits from "../components/institutions/Benefits"
import LandingBitcoinAdvantages from "../components/landingpage/LandingBitcoinAdvantages"
import SecurityTips from "../components/security/Tips"

import { handleLinks, isYoutubeUrl } from "../helpers"

export const renderContentfulBlocks = (block) => {
  const options = {
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br key={i} />, text]),
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const content = node?.data?.target

        // Prevent errors due to zombie entries on Contentful
        if (node?.data?.target?.sys?.type) {
          switch (node.data.target.__typename) {
            case "ContentfulCta":
              return <CTA {...content} source="contentful" />
            case "ContentfulFaqCollection":
              return <FaqCollection {...content} source="contentful" />
            case "ContentfulHero":
              return <Hero {...content} source="contentful" />
            case "ContentfulMarkdownBlock":
              return <MarkdownBlock {...content} source="contentful" />
            case "ContentfulStage":
              return <Stage {...content} source="contentful" />
            case "ContentfulTextAndImageBlock":
              return <TextAndImageBlock {...content} source="contentful" />
            case "ContentfulTextBlock":
              return <TextBlock {...content} source="contentful" />
            case "ContentfulTextBlockWithAside":
              return <TextBlockWithAside {...content} source="contentful" />
            case "ContentfulTextThreeColumns":
              return <Text3Cols {...content} source="contentful" />
            case "ContentfulTextTwoColumns":
              return <Text2Cols {...content} source="contentful" />
            case "ContentfulTextWithHeaderLeft":
              return <TextWithHeaderLeft {...content} source="contentful" />
            case "ContentfulSpecialElement":
              switch (content.element) {
                case "Announcement List":
                  return <AnnouncementList />
                case "CTA: Signup (with device image)":
                  return <CTASignupWithDeviceImage />
                case "Device Animation":
                  return <Device />
                case "FAQs":
                  return <FAQs />
                case "FAQ Top Three":
                  return <FAQTopThree />
                case "Home: Benefits":
                  return <HomeBenefits />
                case "Home: Benefits with Promo":
                  return <HomeBenefitsWithPromo />
                case "Institution Form":
                  return <InstitutionForm />
                case "Landing Page: Bitcoin - Advantages":
                  return <LandingBitcoinAdvantages />
                case "Newsletter Form":
                  return <NewsletterSignup />
                case "Org Chart":
                  return <OrgChart />
                case "Partner Logos Row":
                  return <PartnerLogosRow />
                case "Media Coverage":
                  return <MediaCoverage />
                case "Press: Media Downloads":
                  return <PressDownloads />
                case "Press Releases":
                  return <PressReleases />
                case "Press: Links":
                  return <PressLinks />
                case "Press: Logos":
                  return <PressLogos />
                case "Price Table":
                  return <PriceTable />
                case "Price Ticker (large)":
                  return <PriceTicker format="large" />
                case "Security: Tips":
                  return <SecurityTips />
                case "Support Form":
                  return <SupportForm />
                case "Trading Participants Table":
                  return <TradingParticipantsTable />
                case "Trust Seals":
                  return <TrustSeals />
                default:
                  return null
              }
            default:
              return null
          }
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        return handleLinks({
          href: node.data.uri,
          children: node.content[0].value,
        })
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const text = children[0][1]
        if (text && isYoutubeUrl(text)) {
          return <YouTubePlayer url={text} />
        }
        return <p>{children}</p>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const mimeType = node?.data?.target?.file?.contentType
        const mimeGroup = mimeType?.split("/")[0]

        if (mimeType && mimeGroup) {
          switch (mimeGroup) {
            case "image":
              return (
                <ImageContentful
                  alt={node.data.target.description}
                  image={node.data.target}
                  type={mimeType}
                />
              )
            default:
              return null
          }
        }
      },
    },
  }

  return renderRichText(block, options)
}

export const RenderContentfulSections = ({ sections }) => {
  return sections.length > 0
    ? sections.map((section, i) => {
        // Prevent errors due to zombie entries on Contentful
        if (section?.sys?.contentType?.sys) {
          const defaultProps = {
            key: i,
            source: "contentful",
          }

          switch (section.sys.contentType.sys.id) {
            case "cta":
              return <CTA {...section} {...defaultProps} />
            case "faqCollection":
              return <FaqCollection {...section} {...defaultProps} />
            case "hero":
              return <Hero {...section} {...defaultProps} />
            case "markdownBlock":
              return <MarkdownBlock {...section} {...defaultProps} />
            case "stage":
              return <Stage {...section} {...defaultProps} />
            case "textAndImageBlock":
              return <TextAndImageBlock {...section} {...defaultProps} />
            case "textBlock":
              return <TextBlock {...section} {...defaultProps} />
            case "textBlockWithAside":
              return <TextBlockWithAside {...section} {...defaultProps} />
            case "textThreeColumns":
              return <Text3Cols {...section} {...defaultProps} />
            case "textTwoColumns":
              return <Text2Cols {...section} {...defaultProps} />
            case "textWithHeaderLeft":
              return <TextWithHeaderLeft {...section} {...defaultProps} />
            case "specialElement":
              switch (section.element) {
                case "Announcement List":
                  return <AnnouncementList {...defaultProps} />
                case "CTA: Signup (with device image)":
                  return <CTASignupWithDeviceImage {...defaultProps} />
                case "Device Animation":
                  return <Device {...defaultProps} />
                case "FAQs":
                  return <FAQs {...defaultProps} />
                case "FAQ Top Three":
                  return <FAQTopThree {...defaultProps} />
                case "Home: Benefits":
                  return <HomeBenefits {...defaultProps} />
                case "Home: Benefits with Promo":
                  return <HomeBenefitsWithPromo {...defaultProps} />
                case "Home: Benefits with Email Promo":
                  return <HomeBenefitsWithEmailPromo {...defaultProps} />
                case "Institution Form":
                  return <InstitutionForm {...defaultProps} />
                case "Institutions: Benefits":
                  return <InstitutionsBenefits {...defaultProps} />
                case "Landing Page: Bitcoin - Advantages":
                  return <LandingBitcoinAdvantages {...defaultProps} />
                case "Newsletter Form":
                  return <NewsletterSignup {...defaultProps} />
                case "Org Chart":
                  return <OrgChart {...defaultProps} />
                case "Partner Logos Row":
                  return <PartnerLogosRow {...defaultProps} />
                case "Press: Media Coverage":
                  return <MediaCoverage {...defaultProps} />
                case "Press: Media Downloads":
                  return <PressDownloads {...defaultProps} />
                case "Press Releases":
                  return <PressReleases {...defaultProps} />
                case "Press: Links":
                  return <PressLinks {...defaultProps} />
                case "Press: Logos":
                  return <PressLogos {...defaultProps} />
                case "Price Table":
                  return <PriceTable {...defaultProps} />
                case "Price Table with Signup Button":
                  return <PriceTable {...defaultProps} signupButton />
                case "Price Ticker (large)":
                  return <PriceTicker {...defaultProps} format="large" />
                case "Security: Tips":
                  return <SecurityTips {...defaultProps} />
                case "Support Form":
                  return <SupportForm {...defaultProps} />
                case "Trading Participants Table":
                  return <TradingParticipantsTable {...defaultProps} />
                case "Trust Seals":
                  return <TrustSeals {...defaultProps} />
                case "Micar Markets Services":
                  return <MicarMarketServices {...defaultProps} />
                case "Micar Transparency Data Table":
                  return <MicarTransparencyDataTable {...defaultProps} />
                default:
                  return null
              }
            default:
              return null
          }
        }
      })
    : ""
}
