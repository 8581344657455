import React, { useEffect, useState } from "react"
import axios from "axios";

import { Container, Row, Col } from "./Grid";
import Heading from "./Heading";
import Snippet from "./Snippet";
import Table from "./micarTransparencyData/Table";
import ReportLink from "../helpers/micarTransparencyData/reportLink";

function MicarTransparencyDataTable() {
  const [transparencyDataFiles, setTransparencyDataFiles] = useState([]);

  // get the type and market query params from the URL
  let params;
  if (typeof window === "undefined") {
    params = new URLSearchParams("type=pretrade&asset_id=btc");
  } else {
    params = new URL(document.location.toString()).searchParams;
  }
  const type = params.get("type");
  const assetId = params.get("asset_id");

  if (!type || !assetId) {
    if (typeof window !== "undefined") {
      const locale = document.documentElement.lang || "en";
      window.location.href = `/${locale}/404.html`;
    }
    return (<></>);
  }

  // Titles and subtitles Snippets
  const typeSnippetsMap = {
    pretrade: {
      pageTitlePrefix: Snippet({
        id: "2DFCbzhFu8Z7yuJVivuK5D",
        field: "header"
      }),
      pageSubtitle: Snippet({
        id: "ChRT44wGmVVDGw3zs5YJh",
        field: "text"
      })
    },
    posttrade: {
      pageTitlePrefix: Snippet({
        id: "6KXK0vjlniwd6xgEXScgFY",
        field: "header"
      }),
      pageSubtitle: Snippet({
        id: "5R3WNE2hkaayRBEYF1E62R",
        field: "text"
      })
    }
  };

  // Table Snippets
  const reportLinksBaseUrl = Snippet({
    id: "6YqwlnLZgjA0bh5rxgBUmM",
    field: "linkUrl"
  });
  const tableHeadersString = Snippet({
    id: "7wwPSHHX4pLOIfYiPjaKSO",
    field: "text"
  }); // comma separated headers
  const linkText = Snippet({
    id: "5dIq1KhpLVmAA8LeQWucnu",
    field: "linkText"
  });

  const pageTitle = `${typeSnippetsMap[type].pageTitlePrefix} ${assetId.toUpperCase()}-EUR`;
  const pageSubtitle = typeSnippetsMap[type].pageSubtitle;
  const tableHeaders = tableHeadersString.split(",");

  const renderRow = (row) => (
    <>
      <td>{row.filename}</td>
      <td>{row.timestamp}</td>
      <td>
        <a href={row.url} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
      </td>
    </>
  );

  useEffect(() => {
    const reportLinks = new ReportLink.allForRange({
      type,
      assetId,
      reportLinksBaseUrl
    });
    setTransparencyDataFiles(reportLinks);
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={10} xl={8}>
          <Heading type="h1" className="h1">
            {pageTitle}
          </Heading>
          <p>{pageSubtitle}</p>

          <Table
            headers={tableHeaders}
            rows={transparencyDataFiles}
            renderRow={renderRow}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default MicarTransparencyDataTable;
