import React from "react";
import styled from "styled-components"

import { media } from "../../styles"
import Heading from "../Heading";
import Table from "./Table";

const MicarMarketServicesTable = ({
  services,
  title,
  subTitle,
  tableHeaders
}) => {
  const Wrapper = styled.div`
    padding-bottom: 10px;

    @media ${media.lg} {
      padding-bottom: 70px;
    }
  `;

  const renderRow = (row) => (
    <>
      <td>{row.name}</td>
      <td>
        <a href={row.link}>
          {row.linkText}
        </a>
      </td>
    </>
  );

  return (
    <Wrapper>
      <Heading type="h3" className="h3">
        {title}
      </Heading>
      <p>{subTitle}</p>

      <Table headers={tableHeaders} rows={services} renderRow={renderRow} />
    </Wrapper>
  );
};

export default MicarMarketServicesTable;
