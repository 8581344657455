import React from "react";
import styled from "styled-components";

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  th, td {
    padding: 12px 15px;
  }

  th {
    border-bottom: 1px solid #dddddd;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;

    &:nth-child(odd) {
      background-color: #f3f3f3;
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }
`;

const Table = ({ headers, rows, renderRow }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {renderRow(row, rowIndex)}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default Table;
