export default class ReportLink {
  constructor({ type, assetId, reportLinksBaseUrl, time }) {
    this.type = type;
    this.assetId = assetId;
    this.reportLinksBaseUrl = reportLinksBaseUrl;
    this.time = time;
  }

  static allForRange({
    type,
    assetId,
    reportLinksBaseUrl,
    startDate = new Date().setUTCHours(0, 0, 0, 0),
    endDate = new Date(),
    step = 5 * 60 * 1000, // 5 minutes in milliseconds
  }) {
    const reportLinks = [];

    for (
      let timestamp = new Date(startDate).getTime();
      // end date - step: Report timestamp is the start of the report's period
      // so this report will only be available after the step interval
      timestamp <= new Date(endDate).getTime() - step;
      timestamp += step
    ) {
      reportLinks.push(
        new ReportLink({
          type,
          assetId,
          reportLinksBaseUrl,
          time: new Date(timestamp)
        }));
    }

    return reportLinks.reverse();
  }

  get filename() {
    const name = [
      'XDEX',
      this.type,
      this.market,
      this.formatFilenameTime(this.time)
    ].join('-');
    const extension = 'json.gz';
    return `${name}.${extension}`;
  }

  get url() {
    return new URL(`${this.reportLinksBaseUrl}/${this.urlPath}`)
      .toString();
  }

  get timestamp() {
    const date = [
      String(this.time.getUTCDate()).padStart(2, '0'), // d
      String(this.time.getUTCMonth() + 1).padStart(2, '0'), // m
      this.time.getUTCFullYear() // Y
    ].join("."); // d.m.Y

    const time = [
      String(this.time.getUTCHours()).padStart(2, '0'), // H
      String(this.time.getUTCMinutes()).padStart(2, '0') // M
    ].join(":"); // H:M
    return `${date} ${time}`; // d.m.Y H:M in UTC
  }

  get urlPath() {
    return [
      this.market,
      this.type,
      this.time.getUTCFullYear(),
      String(this.time.getUTCMonth() + 1).padStart(2, '0'),
      String(this.time.getUTCDate()).padStart(2, '0'),
      this.filename,
    ].join('/');
  }

  get market() {
    return `${this.assetId}-eur`;
  }

  formatFilenameTime() {
    const date = [
      this.time.getFullYear(), // Y
      String(this.time.getUTCMonth() + 1).padStart(2, '0'), // m
      String(this.time.getUTCDate()).padStart(2, '0'), // d
    ].join(''); // Ymd

    const time = [
      String(this.time.getUTCHours()).padStart(2, '0'), // H
      String(this.time.getUTCMinutes()).padStart(2, '0'), // m
    ].join(''); // HM
    return `${date}T${time}` // YmdHM in UTC
  }
}
